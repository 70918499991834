import React, { Component } from 'react';

import axios from 'axios';
import JSONBigInt from 'json-bigint'

import './App.css';

function renderWithLinks(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.split(urlRegex).map((segment, index) => {
    if (urlRegex.test(segment)) { // 如果当前片段是网址
      let hostname = ""
      try {
              hostname = new URL(segment).hostname
      } catch (error) {
              console.error(error);
              console.warn(segment, "=====", text);
              return segment;
      }
      return (
        <a href={segment} key={index} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
          {hostname}
        </a>
      );
    } else { // 如果当前片段不是网址
      return segment;
    }
  });
}

const UTCStringToLocalTime = (UTCString) => {
    const date = new Date(UTCString)
    return date.toLocaleString('en-NZ', { timeZone: 'Pacific/Auckland', })
}

const HarmonyAnchor = ({ link, content }) => (
    <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
        {content}
    </a>
);

const Tweet = ({ tweet }) => (
    <li
        className="list-group-item"
        style={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
            backgroundColor: "#f0f3fb",
            // marginLeft: "0.5rem",
            marginBottom: "0.3rem",
        }}
    >
        <div style={{
            marginLeft: '0.5em',
            marginTop: '0.2em',
            fontFamily: 'HelveticaNeue',
            fontSize: "1.7em",
            fontWeight: '300',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '-0.6px',
        }}>
              <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <span>
                          <HarmonyAnchor link={`https://twitter.com/${tweet.ScreenName}`} content={tweet.Username} />
                      </span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-block d-lg-none">
                      <span>
                          <HarmonyAnchor link={`https://twitter.com/${tweet.ScreenName}/status/${tweet.StatusID}`} content={UTCStringToLocalTime(tweet.CreatedTime)} />
                      </span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-none d-lg-block">
                      <span style={{ float: 'right' }}>
                          <HarmonyAnchor link={`https://twitter.com/${tweet.ScreenName}/status/${tweet.StatusID}`} content={UTCStringToLocalTime(tweet.CreatedTime)} />
                      </span>
                  </div>
              </div>
              <br />
              <span>
                  {renderWithLinks(tweet.Text)}
              </span>
        </div>
    </li>
);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tweets: [],
            anchor: 0,
            limit: 50,
            sort: '-id',
            search: '',
        };
        this.handleLimitChange = this.handleLimitChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clickNextPage = this.clickNextPage.bind(this);
        this.clickPreviousPage = this.clickPreviousPage.bind(this);
    }

    componentDidMount() {
        this.searchTweets();
    }

    searchTweets() {
        axios.get("/api.json", {
            transformResponse: [data => data],
            params: {
                anchor: this.state.anchor,
                search: this.state.search,
                limit:  this.state.limit,
                sort: this.state.sort,
            },
        })
        .then((response) => {
            const data = JSONBigInt.parse(response.data).data;
            this.setState({
                tweets: data,
            })
        })
        .catch((error) => {
          console.error(error);
        });
    }

    handleSearchChange(event) {
        this.setState({
            search: event.target.value,
            anchor: 0,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.searchTweets()
    }

    handleLimitChange(event) {
        this.setState({
            limit: event.target.value,
        }, this.searchTweets);
    }

    clickPreviousPage() {
        this.setState(previousState => ({
            anchor: -previousState.tweets[0].ID,
        }), this.searchTweets)
        window.scrollTo(0, 0);
    }

    clickNextPage() {
        this.setState(previousState => ({
            anchor: previousState.tweets[previousState.tweets.length - 1].ID,
        }), this.searchTweets)
        window.scrollTo(0, 0);
    }

    render() {
        const { tweets, search, limit } = this.state;
        return (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center">
                      <a href="/" style={{ textDecoration: "none" }}>
                          Timeline
                      </a>
                  </h3>
                  <form className="form-inline" onSubmit={this.handleSubmit}>
                    <div className="input-group mb-3">
                      <input
                          type="text"
                          className="form-control"
                          id="search-input"
                          name="search"
                          onChange={e => this.handleSearchChange(e)}
                          defaultValue={search}
                          placeholder="Search keywords..."
                          aria-label="Search-keywords"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-secondary" type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                  <ul className="list-group">
                    {tweets.map((tweet) => <Tweet key={tweet.ID} tweet={tweet} />)}
                  </ul>
                  <nav aria-label="Page navigation">
                    <ul className="pagination mt-3 justify-content-center">
                        <li key="previous" className="page-item">
                            <button className="page-link" onClick={this.clickPreviousPage}>Previous</button>
                        </li>
                        <li key="center" className="page-item">
                            <select id="page-size" className="h-100" value={limit} onChange={this.handleLimitChange}>
                              <option value="50" >Page size: 50</option>
                              <option value="100">Page size: 100</option>
                              <option value="200">Page size: 200</option>
                              <option value="500">Page size: 500</option>
                            </select>
                        </li>
                        <li key="next" className="page-item">
                            <button className="page-link" onClick={this.clickNextPage}>Next</button>
                        </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
        );
    }
}

export default App;
